export function formatSeriesName(series: string): string {
    return series
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '); 
}
  

export function formatWearStatus(wear: string): string {
    return wear.charAt(0).toUpperCase() + wear.slice(1).toLowerCase();
}