import React, { useState, useEffect } from 'react';
import { fetchWithToken } from '../utils/fetchUtils';
import styles from './AdminDashboard.module.css';

const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'scp' | 'facility' | 'user'>('scp');
  const [users, setUsers] = useState<any[]>([]);
  const [scps, setScps] = useState<any[]>([]);
  const [userId, setUserId] = useState('');
  const [scpId, setScpId] = useState('');
  const [wear, setWear] = useState('');
  const [message, setMessage] = useState('');

  // Facility Management states
  const [facilityId, setFacilityId] = useState('');
  const [hoursToSkip, setHoursToSkip] = useState('');

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const handleTimeSkip = async () => {
    if (!facilityId || isNaN(parseInt(facilityId))) {
      setMessage('Invalid facility ID.');
      return;
    }
    if (!hoursToSkip || isNaN(parseFloat(hoursToSkip))) {
      setMessage('Invalid hours to skip.');
      return;
    }

    try {
      const response = await fetchWithToken(`facility/${facilityId}/time-skip`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hours: parseFloat(hoursToSkip) }),
      });

      if (response.ok) {
        setMessage(`Skipped ${hoursToSkip} hours for facility ${facilityId}`);
      } else {
        setMessage(`Error skipping time: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error skipping time:', error);
      setMessage('Error skipping time');
    }
  };

  const handleCalculateResources = async () => {
    if (!facilityId || isNaN(parseInt(facilityId))) {
      setMessage('Invalid facility ID.');
      return;
    }

    try {
      const response = await fetchWithToken(`facility/${facilityId}/calculate-resources`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.ok) {
        const data = await response.data;
        setMessage(`Resources updated for facility ${facilityId}`);
        console.log('Updated Resources:', data);
      } else {
        setMessage(`Error calculating resources: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error calculating resources:', error);
      setMessage('Error calculating resources');
    }
  };

  useEffect(() => {
    if (activeTab === 'user') {
      fetchUsers();
    } else if (activeTab === 'scp') {
      fetchScps();
    }
    setCurrentPage(1);
  }, [activeTab]);

  const fetchScps = async () => {
    try {
      const response = await fetchWithToken('scps', {
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
  
      if (response.ok) {
        setScps(response.data);
      } else {
        console.error('Failed to fetch SCPs:', response);
      }
    } catch (error) {
      console.error('Error fetching SCPs:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetchWithToken('users', {
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.ok) {
        const data = await response.data;
        setUsers(data);
      } else {
        console.error('Failed to fetch users:', response);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddCard = async () => {
    if (!userId || isNaN(parseInt(userId))) {
      setMessage("Invalid user ID. Please enter a valid number.");
      return;
    }
  
    if (!scpId || isNaN(parseInt(scpId))) {
      setMessage("Invalid SCP ID. Please enter a valid number.");
      return;
    }
  
    try {
      const response = await fetchWithToken(`users/${userId}/addCard`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ scp_id: parseInt(scpId), wear }),
      });
  
      if (response.ok) {
        setMessage('Card added successfully');
      } else {
        setMessage(`Error adding card: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error adding card:', error);
      setMessage('Error adding card');
    }
  };

  const handleRemoveCard = async () => {
      try {
          const response = await fetchWithToken(`users/${parseInt(userId)}/removeCard`, {
              method: 'POST',
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json' },
              body: JSON.stringify({ scp_id: parseInt(scpId), wear }),
          });

          if (response.ok) {
              setMessage('Card removed successfully');
          } else {
              setMessage(`Error removing card: ${response.statusText}`);
          }
      } catch (error) {
          console.error('Error removing card:', error);
          setMessage('Error removing card');
      }
  };

  const totalPages = Math.ceil((activeTab === 'scp' ? scps.length : users.length) / recordsPerPage);
  const currentRecords = (activeTab === 'scp' ? scps : users).slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  return (
    <div className={styles['admin-dashboard']}>
      <h1 className={styles.h1}>Admin Dashboard</h1>

      {/* Tabs Navigation */}
      <div className={styles.tabs}>
        <button onClick={() => setActiveTab('scp')} className={activeTab === 'scp' ? `${styles.active}` : ''}>
          SCP Management
        </button>
        <button onClick={() => setActiveTab('facility')} className={activeTab === 'facility' ? `${styles.active}` : ''}>
          Facility Management
        </button>
        <button onClick={() => setActiveTab('user')} className={activeTab === 'user' ? `${styles.active}` : ''}>
          User Management
        </button>
      </div>

      {/* Content Area for Active Tab */}
      <div className={styles['tab-content']}>
        {activeTab === 'scp' && (
          <div>
            <h2>SCP Management</h2>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Base Number</th>
                  <th>SCP ID</th>
                  <th>Name</th>
                  <th>Motto</th>
                  <th>Classification</th>
                  <th>SCP Number</th>
                  <th>Maintenance Cost</th>
                  <th>Rarity</th>
                  <th>Image URL</th>
                  <th>Wear</th>
                  <th>Variant</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((scp) => (
                  <tr key={scp.id}>
                    <td>{scp.id}</td>
                    <td>{scp.base_number}</td>
                    <td>{scp.scp_id}</td>
                    <td>{scp.name}</td>
                    <td>{scp.motto || 'N/A'}</td>
                    <td>{scp.classification}</td>
                    <td>{scp.scp_number}</td>
                    <td>{scp.maintenance_cost || 'N/A'}</td>
                    <td>{scp.rarity || 'N/A'}</td>
                    <td>{scp.image_url ? <a href={scp.image_url} target="_blank" rel="noopener noreferrer">Image</a> : 'N/A'}</td>
                    <td>{scp.wear || 'N/A'}</td>
                    <td>{scp.is_variant ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className={styles.pagination}>
              <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>

            {/* SCP Card Management */}
            <div className={styles['button-group']}>
              <input
                placeholder="User ID"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
              <input
                placeholder="SCP ID"
                value={scpId}
                onChange={(e) => setScpId(e.target.value)}
              />
              <input
                placeholder="Wear"
                value={wear}
                onChange={(e) => setWear(e.target.value)}
              />
              <button onClick={handleAddCard}>Add SCP to User</button>
              <button onClick={handleRemoveCard}>Remove SCP from User</button>
              {message && <p>{message}</p>}
            </div>
          </div>
        )}

        {activeTab === 'facility' && (
          <div>
            <h2>Facility Management</h2>
            {/* Facility Time Skipper */}
            <div>
              <h3>Time Skipper</h3>
              <input
                placeholder="Facility ID"
                value={facilityId}
                onChange={(e) => setFacilityId(e.target.value)}
              />
              <input
                placeholder="Hours to Skip"
                value={hoursToSkip}
                onChange={(e) => setHoursToSkip(e.target.value)}
              />
              <button onClick={handleTimeSkip}>Skip Time</button>
              <button onClick={handleCalculateResources}>Calculate Resources</button>
                {message && <p>{message}</p>}
            </div>
            <p>Feature under construction...</p>
          </div>
        )}

        {activeTab === 'user' && (
          <div>
            <h2>User Management</h2>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Level</th>
                  <th>Experience</th>
                  <th>Prestige Level</th>
                  <th>Verified</th>
                  <th>Facility ID</th>
                  <th>Roles</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.level}</td>
                    <td>{user.experience}</td>
                    <td>{user.prestige_level}</td>
                    <td>{user.isVerified ? 'Yes' : 'No'}</td>
                    <td>{user.facility_id || 'N/A'}</td>
                    <td>{user.roles ? user.roles.join(', ') : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className={styles.pagination}>
              <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;