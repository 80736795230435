import { fetchWithToken } from '../utils/fetchUtils';

export const updateUserAvatar = async (userId: number, avatarId: number): Promise<void> => {
  try {
    await fetchWithToken(`users/${userId}/avatar`, {
      method: 'PUT',
      body: JSON.stringify({ avatarId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Failed to update avatar:', error);
    throw error;
  }
};
