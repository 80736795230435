import { Handle, Position } from 'react-flow-renderer';
import { useTheme } from '@mui/joy';
import Button from '@mui/joy/Button';
import { ResearchNodeData } from './types';
import LockIcon from '@mui/icons-material/Lock';

const handleStyle = {
  opacity: 0,
  width: 1,
  height: 1,
  minWidth: '100%',
  left: 0,
  transform: 'none',
  background: 'transparent',
};

const lockedPulse = {
  '@keyframes lockedPulse': {
    '0%, 100%': {
      boxShadow: '0 0 0 rgba(255, 255, 255, 0)',
    },
    '50%': {
      boxShadow: '0 0 8px rgba(255, 255, 255, 0.1)',
    },
  },
};

const unlockedBlueGlow = {
  '@keyframes unlockedBlueGlow': {
    '0%, 100%': {
      boxShadow: '0 0 8px rgba(0, 153, 255, 0.2)',
    },
    '50%': {
      boxShadow: '0 0 16px rgba(0, 153, 255, 0.5)',
    },
  },
};

export function LockedNode({
  data,
  isConnectable,
}: {
  data: ResearchNodeData;
  isConnectable: boolean;
}) {
  return (
    <div
      style={{
        position: 'relative',
        opacity: 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        style={handleStyle}
      />

      <Button
        variant="outlined"
        startDecorator={<LockIcon sx={{ fontSize: '1.1rem', opacity: 0.8 }} />}
        sx={{
          ...lockedPulse,
          '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            backgroundImage: `repeating-linear-gradient(
              45deg,
              rgba(255, 255, 255, 0.03),
              rgba(255, 255, 255, 0.03) 8px,
              rgba(0, 0, 0, 0) 8px,
              rgba(0, 0, 0, 0) 16px
            )`,
            borderRadius: '4px',
          },
          backgroundColor: 'rgba(30, 30, 30, 0.6)',
          color: 'rgba(255, 255, 255, 0.5)',
          padding: '8px 16px',
          borderRadius: '4px',
          fontSize: '0.9rem',
          minHeight: '40px',
          minWidth: '200px',
          cursor: 'pointer',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(8px)',
          transition: 'transform 0.2s ease, background-color 0.3s ease',
          position: 'relative',
          zIndex: 1,
          animation: 'lockedPulse 4s ease-in-out infinite',
          '&:hover': {
            transform: 'scale(1.02)',
            backgroundColor: 'rgba(40, 40, 40, 0.7)',
          },
        }}
      >
        {data.label}
      </Button>

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        style={handleStyle}
      />
    </div>
  );
}

export function UnlockedNode({
  data,
  isConnectable,
}: {
  data: ResearchNodeData;
  isConnectable: boolean;
}) {
  return (
    <div
      style={{
        opacity: 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        style={handleStyle}
      />

      <Button
        variant="outlined"
        sx={{
          ...unlockedBlueGlow,
          backgroundColor: 'rgba(50, 50, 50, 0.8)',
          color: 'rgba(255, 255, 255, 0.9)',
          padding: '8px 16px',
          borderRadius: '4px',
          fontSize: '0.9rem',
          minHeight: '40px',
          minWidth: '200px',
          cursor: 'pointer',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(8px)',
          transition: 'transform 0.2s ease, background-color 0.3s ease',
          animation: 'unlockedBlueGlow 4s ease-in-out infinite',
          '&:hover': {
            transform: 'scale(1.02)',
            backgroundColor: 'rgba(60, 60, 60, 0.9)',
          },
        }}
      >
        {data.label}
      </Button>

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        style={handleStyle}
      />
    </div>
  );
}
