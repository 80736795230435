import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { useUser } from '../context/UserContext';
import { useFacility } from '../context/FacilityContext';
import { db } from '../../src/firestore';
import { collection, getDocs, DocumentData, orderBy, query } from 'firebase/firestore';
import '../styles/base.css';
import styles from './Home.module.css';

interface Announcement {
  id: string;
  title: string;
  content: string;
  date: any;
  author?: string;
}

export default function Home() {
  const { user } = useUser();
  const { facility } = useFacility();

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const q = query(collection(db, 'announcements'), orderBy('date', 'desc'));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc: DocumentData) => ({
          id: doc.id,
          ...doc.data(),
        })) as Announcement[];
        setAnnouncements(data);
      } catch (error) {
        console.error('Error fetching Firestore data:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  return (
    <div className="app-background">
      <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0} />
      <div className="page-header-text">
        <h1>HOME</h1>
      </div>
      <div className={styles.dashboardContainerGrid}>
        <div className={`${styles.panel} ${styles.leftPanel}`}>
          <div className="scp-of-the-day">
            <h2 className={styles.panelHeader}>SCP OF THE MONTH</h2>
            <div className="firestore-data">
              {/* SCP of the day content */}
            </div>
          </div>
        </div>
        <div className={`${styles.panel} ${styles.centerPanel}`}>
          <div className="events">
            <h2 className={styles.panelHeader}>ANNOUNCEMENTS</h2>
            <div className="announcements">
              {announcements.length > 0 ? (
                announcements.map((announcement) => (
                  <div key={announcement.id} className={styles.announcementItem}>
                    <h3 className={styles.announcementTitle}>{announcement.title}</h3>
                    <p className={styles.announcementDate}>
                      {announcement.date && announcement.date.toDate
                        ? `${announcement.date.toDate().toLocaleDateString()} ${announcement.date
                            .toDate()
                            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
                        : 'Date not available'}
                    </p>
                    <div
                      className={styles.announcementContent}
                      dangerouslySetInnerHTML={{ __html: announcement.content }}
                    />
                    {announcement.author && (
                      <p className={styles.announcementAuthor}>Posted by: {announcement.author}</p>
                    )}
                  </div>
                ))
              ) : (
                <p>No announcements available</p>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.panel} ${styles.rightPanel}`}>
          <div className="leaderboard">
            <h2 className={styles.panelHeader}>LEADERBOARD</h2>
          </div>
        </div>
      </div>
    </div>
  );
}