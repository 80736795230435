import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useUser } from '../context/UserContext';
import Button from '@mui/joy/Button';
import { fetchWithToken } from '../utils/fetchUtils';
import theme from '../joyTheme';
import styles from '../pages/FacilityManager.module.css';
import '../styles/base.css';

export default function BuildingDetail() {
  const { buildingId } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const [buildingData, setBuildingData] = useState<any>(null);
  const [view, setView] = useState('overview');

  useEffect(() => {
    const fetchBuildingData = async () => {
      if (!user?.id || !buildingId) return;
      
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token available');
        
        const response = await fetchWithToken(`facility/${user.id}/building/${buildingId}`, {
          method: 'GET',
        }, token);

        if (response.ok) {
          setBuildingData(response.data);
        }
      } catch (error) {
        console.error('Error fetching building data:', error);
      }
    };

    fetchBuildingData();
  }, [buildingId, user]);

  const buttonStyle = {
    fontFamily: (theme.typography as any).fontFamily,
  };

  return (
    <div className="app-background">
      <Navbar username={user?.username || 'Guest'} userId={user?.id || 0} />
      <div className="page-header-text">
        <h1>{buildingData?.name || buildingId}</h1>
      </div>
      <div className={styles.facilityManagerNavigationHeader}>
        <Button variant="outlined" onClick={() => navigate('/facility-manager?view=facility-management')}>← BACK TO FACILITY</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('overview')}>OVERVIEW</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('upgrades')}>UPGRADES</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('management')}>MANAGEMENT</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('stats')}>STATS</Button>
      </div>
      <div className={styles.facilityManagerContainer}>
        <div className={styles.panel}>
          {view === 'overview' && <div>Overview Content</div>}
          {view === 'upgrades' && <div>Upgrades Content</div>}
          {view === 'management' && <div>Management Content</div>}
          {view === 'stats' && <div>Stats Content</div>}
        </div>
      </div>
    </div>
  );
} 