import apiUrl from '../config';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface FetchOptions {
    method: HttpMethod;
    body?: any;
    headers?: HeadersInit;
}

export async function fetchWithToken(url: string, options: FetchOptions, token?: string): Promise<any> {
  const authToken = token || localStorage.getItem('token');

  if (url.startsWith('http://') || url.startsWith('https://')) {
    const urlObject = new URL(url);
    url = urlObject.pathname.replace('/api', '');
  }

  const headers = new Headers(options.headers || {});
  if (authToken) {
    headers.set('Authorization', `Bearer ${authToken}`);
  }
  headers.set('Content-Type', 'application/json');

  console.log('Headers being sent:', Object.fromEntries(headers.entries()));

  try {
    const response = await fetch(`${apiUrl}/api/${url}`, {
      ...options,
      headers,
    });

    const contentType = response.headers.get('Content-Type');
    const data = contentType && contentType.includes('application/json')
      ? await response.json()
      : await response.text();

    if (!response.ok) {
      console.error('Response error:', data);
      throw new Error(`Error ${response.status}: ${response.statusText} - ${data}`);
    }

    return {
      ok: response.ok,
      status: response.status,
      data: typeof data === 'object' ? data : { message: data },
    };
  } catch (error) {
    console.error('Fetch error:', error);
    throw new Error('Internal Server Error');
  }
}

export default fetchWithToken;
