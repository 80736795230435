import React from 'react';
import Button from '@mui/joy/Button';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SCPCard from '../SCPCard';
import { formatSeriesName, formatWearStatus } from '../../utils/formatters';
import { Typography } from '@mui/material';
import styles from './SCPModal.module.css';

interface SCPData {
    id: number;
    base_number: string;
    scp_number: string;
    name: string;
    motto: string;
    classification: string;
    image_url: string;
    rarity?: string;
    wear?: string;
    is_variant?: boolean;
    maintenance_cost?: number;
    series?: string;
    acquired_at?: string;
}

interface SCPModalProps {
    selectedSCP: SCPData;
    selectedSCPVariants: SCPData[];
    selectedVariantIndex: number;
    handlePrevVariant: () => void;
    handleNextVariant: () => void;
    isOwned: (scp: SCPData) => boolean;
    handleClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SCPModal: React.FC<SCPModalProps> = ({
    selectedSCP,
    selectedSCPVariants,
    selectedVariantIndex,
    handlePrevVariant,
    handleNextVariant,
    isOwned,
    handleClose,
}) => {
    const currentSCP = selectedSCPVariants[selectedVariantIndex] || selectedSCP;

    return (
        <div className={styles.scpModalOverlay} onClick={handleClose}>
            <div
                className={`${styles.scpModalContent}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.scpModalLeft}>
                    <div className={styles.scpCardWithArrows}>
                        <SCPCard
                            scp={currentSCP}
                            onSelect={() => {}}
                            isModalView
                            isOwned={isOwned(currentSCP)}
                        />
                        {currentSCP.is_variant && isOwned(currentSCP) && (
                        <div className={styles.scpModalNavigationArrows}>
                            <Button
                            onClick={handlePrevVariant}
                            variant="outlined"
                            disabled={selectedVariantIndex === 0}
                            className={styles.navButton}
                            >
                            Back
                            </Button>

                            <Button
                            onClick={handleNextVariant}
                            variant="outlined"
                            disabled={selectedVariantIndex === selectedSCPVariants.length - 1}
                            className={styles.navButton}
                            >
                            Next
                            </Button>
                        </div>
                        )}
                    </div>
                </div>
                <div className={styles.scpModalRight}>
                    <Typography variant="body2" className={styles.scpNumber}>
                        {currentSCP.base_number}
                    </Typography>
                    <Typography variant="h3" className={styles.scpModalTitle}>
                        {currentSCP.name}
                    </Typography>
                    <Typography variant="body1" className={styles.scpMotto}>
                        {currentSCP.motto}
                    </Typography>
                    <div className={styles.scpInfo}>
                        <div className={styles.scpDetail}>
                            <Typography variant="h6" component="label" className={styles.detailLabel}>
                                Classification
                            </Typography>
                            <Typography variant="body2" className={styles.detailValue}>
                                {currentSCP.classification}
                            </Typography>
                        </div>
                        {currentSCP.is_variant && (
                            <>
                                <div className={styles.scpDetail}>
                                    <Typography variant="h6" component="label" className={styles.detailLabel}>
                                        Rarity
                                    </Typography>
                                    <Typography variant="body2" className={styles.detailValue}>
                                        {currentSCP.rarity
                                            ? currentSCP.rarity.charAt(0).toUpperCase() + currentSCP.rarity.slice(1).toLowerCase()
                                            : 'Common'}
                                    </Typography>
                                </div>
                                <div className={styles.scpDetail}>
                                    <Typography variant="h6" component="label" className={styles.detailLabel}>
                                        Wear
                                    </Typography>
                                    <Typography variant="body2" className={styles.detailValue}>
                                        {formatWearStatus(currentSCP.wear || '  ')}
                                    </Typography>
                                </div>
                                <div className={styles.scpDetail}>
                                    <Typography variant="h6" component="label" className={styles.detailLabel}>
                                        Series
                                    </Typography>
                                    <Typography variant="body2" className={styles.detailValue}>
                                       {formatSeriesName(currentSCP.series || '')}
                                    </Typography>
                                </div>
                            </>
                        )}
                        <div className={styles.scpDetail}>
                            <Typography variant="h6" component="label" className={styles.detailLabel}>
                                Ownership Status
                            </Typography>
                            <Typography variant="body2" className={styles.detailValue}>
                                {isOwned(currentSCP) ? 'Owned' : 'Not Owned'}
                            </Typography>
                        </div>
                        <div className={styles.scpDetail}>
                            <Typography variant="h6" component="label" className={styles.detailLabel}>
                                Maintenance Cost
                            </Typography>
                            <Typography variant="body2" className={styles.detailValue}>
                                {currentSCP.maintenance_cost ? `$${currentSCP.maintenance_cost}` : 'N/A'}
                            </Typography>
                        </div>
                        {isOwned(currentSCP) && (
                            <div className={styles.scpDetail}>
                                <Typography variant="h6" component="label" className={styles.detailLabel}>
                                    Acquired
                                </Typography>
                                <Typography variant="body2" className={styles.detailValue}>
                                    {currentSCP.acquired_at ? new Date(currentSCP.acquired_at).toLocaleString() : 'N/A'}
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SCPModal;
