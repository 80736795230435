import { Position } from 'react-flow-renderer';
import { InitialEdges, ResearchNodesByView, ResearchNode } from './types';

// TODO: Migrate all data to jsons, one for each research area

export const initialEdges: InitialEdges = {
  containment_protocol_training_facility: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  anomalous_energy_plant: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  scp_containment_center: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  research_laboratory: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
    { id: 'e2-4', source: '2', target: '4', animated: true, style: { stroke: '#000' } },
  ],
  anomaly_research_center: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  central_command_center: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
};

export const calculateInitialPositions = (viewportWidth: number, viewportHeight: number): ResearchNodesByView => {
  const centerX = viewportWidth / 2;
  const centerY = viewportHeight / 2;

  return {
    research_laboratory: [
      { 
        id: '1', 
        type: 'unlocked',
        data: { 
          label: 'Basic Research Techniques',
          isLocked: false,
          description: 'Fundamental research methodologies and basic laboratory protocols.',
          cost: {
            credits: 100,
            energy: 50,
          }
        }, 
        position: { x: centerX - 75, y: centerY - 200 }, 
        style: { backgroundColor: 'transparent' }, 
        sourcePosition: Position.Bottom 
      },
      { 
        id: '2',
        type: 'locked',
        data: { 
          label: 'Enhanced Microscopes',
          isLocked: true,
          description: 'Advanced microscopy equipment for detailed specimen analysis.',
          requirements: ['1'],
          cost: {
            credits: 200,
            energy: 75,
            materials: 50
          }
        }, 
        position: { x: centerX - 225, y: centerY - 50 }, 
        style: { backgroundColor: 'transparent' }, 
        targetPosition: Position.Top, 
        sourcePosition: Position.Bottom 
      },
      { 
        id: '3',
        type: 'locked',
        data: { 
          label: 'Basic Containment Analysis',
          isLocked: true,
          description: 'Fundamental containment analysis techniques.',
          requirements: ['1'],
          cost: {
            credits: 150,
            energy: 60
          }
        },
        position: { x: centerX + 50, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
      { 
        id: '4',
        type: 'locked',
        data: { 
          label: 'Advanced Bio-Chemical Instruments',
          isLocked: true,
          description: 'High-end equipment for complex biochemical analysis.',
          requirements: ['2', '3'],
          cost: {
            credits: 300,
            energy: 100,
            materials: 100
          }
        },
        position: { x: centerX + 50, y: centerY + 100 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
    ],
    containment_protocol_training_facility: [
      { 
        id: '1',
        type: 'unlocked',
        data: { 
          label: 'Surveillance Training Program',
          isLocked: false,
          description: 'Basic surveillance and monitoring protocols.',
          cost: {
            credits: 100,
            energy: 50
          }
        },
        position: { x: centerX - 75, y: centerY - 200 },
        style: { backgroundColor: 'transparent' },
        sourcePosition: Position.Bottom
      },
      { 
        id: '2',
        type: 'locked',
        data: { 
          label: 'Basic Containment Drills',
          isLocked: true,
          description: 'Basic containment drills and protocols.',
          requirements: ['1'],
          cost: {
            credits: 150,
            energy: 60
          }
        },
        position: { x: centerX - 225, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
      { 
        id: '3',
        type: 'locked',
        data: { 
          label: 'Emergency Response Drill Zone',
          isLocked: true,
          description: 'Advanced emergency response training facility.',
          requirements: ['2'],
          cost: {
            credits: 200,
            energy: 80,
            materials: 50
          }
        },
        position: { x: centerX + 50, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
    ],
    anomalous_energy_plant: [
      { 
        id: '1',
        type: 'unlocked',
        data: { 
          label: 'Enhanced EM Harvesters',
          isLocked: false,
          description: 'Advanced electromagnetic energy harvesting systems.',
          cost: {
            credits: 150,
            energy: 75
          }
        },
        position: { x: centerX - 75, y: centerY - 200 },
        style: { backgroundColor: 'transparent' },
        sourcePosition: Position.Bottom
      },
      { 
        id: '2',
        type: 'locked',
        data: { 
          label: 'Improved Thermal Converters',
          isLocked: true,
          description: 'Enhanced thermal energy conversion systems.',
          requirements: ['1'],
          cost: {
            credits: 250,
            energy: 100,
            materials: 75
          }
        },
        position: { x: centerX - 225, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
    ],
    scp_containment_center: [
      { 
        id: '1',
        type: 'unlocked',
        data: { 
          label: 'Reinforced Safe Containment',
          isLocked: false,
          description: 'Basic SCP containment protocols and facilities.',
          cost: {
            credits: 200,
            energy: 100
          }
        },
        position: { x: centerX - 75, y: centerY - 200 },
        style: { backgroundColor: 'transparent' },
        sourcePosition: Position.Bottom
      },
      { 
        id: '2',
        type: 'locked',
        data: { 
          label: 'Enhanced Euclid Monitoring',
          isLocked: true,
          description: 'Advanced monitoring systems for Euclid-class entities.',
          requirements: ['1'],
          cost: {
            credits: 300,
            energy: 150,
            materials: 100
          }
        },
        position: { x: centerX - 225, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
      { 
        id: '3',
        type: 'locked',
        data: { 
          label: 'Advanced Keter Containment',
          isLocked: true,
          description: 'Specialized containment systems for Keter-class entities.',
          requirements: ['1', '2'],
          cost: {
            credits: 500,
            energy: 250,
            materials: 200
          }
        },
        position: { x: centerX + 50, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
    ],
    anomaly_research_center: [
      { 
        id: '1',
        type: 'unlocked',
        data: { 
          label: 'Basic Anomaly Analysis',
          isLocked: false,
          description: 'Fundamental anomaly research capabilities.',
          cost: {
            credits: 150,
            energy: 75
          }
        },
        position: { x: centerX - 75, y: centerY - 200 },
        style: { backgroundColor: 'transparent' },
        sourcePosition: Position.Bottom
      },
      { 
        id: '2',
        type: 'locked',
        data: { 
          label: 'Anomalous Energy Containment',
          isLocked: true,
          description: 'Advanced systems for containing and studying anomalous energy.',
          requirements: ['1'],
          cost: {
            credits: 300,
            energy: 150,
            materials: 100
          }
        },
        position: { x: centerX - 225, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
    ],
    central_command_center: [
      { 
        id: '1',
        type: 'unlocked',
        data: { 
          label: 'Enhanced Surveillance Integration',
          isLocked: false,
          description: 'Advanced facility monitoring and control systems.',
          cost: {
            credits: 200,
            energy: 100
          }
        },
        position: { x: centerX - 75, y: centerY - 200 },
        style: { backgroundColor: 'transparent' },
        sourcePosition: Position.Bottom
      },
      { 
        id: '2',
        type: 'locked',
        data: { 
          label: 'Basic Command Coordination',
          isLocked: true,
          description: 'Improved facility coordination and response systems.',
          requirements: ['1'],
          cost: {
            credits: 350,
            energy: 175,
            materials: 150
          }
        },
        position: { x: centerX - 225, y: centerY - 50 },
        style: { backgroundColor: 'transparent' },
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom
      },
    ],
  };
};

export const calculateTranslateExtent = (viewportWidth: number, viewportHeight: number): [[number, number], [number, number]] => {
  const padding = 200;
  return [
    [0 - padding, 0 - padding],
    [viewportWidth + padding, viewportHeight + padding],
  ];
};

export const canUnlockNode = (nodeId: string, unlockedNodes: Set<string>, nodes: ResearchNode[]): boolean => {
  const node = nodes.find(n => n.id === nodeId);
  if (!node || !node.data.requirements) return true;
  
  return node.data.requirements.every((reqId: string) => unlockedNodes.has(reqId));
}; 