import React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import styles from './Navbar.module.css';

interface NavbarProps {
  username: string;
  userId: number;
}

const Navbar: React.FC<NavbarProps> = ({ username, userId }) => {
  const { setUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('facility');

    setUser(null);

    navigate('/login');
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLinks}>
        <Link to="/home">HOME</Link>
        <Link to="/scp-collection">SCP COLLECTION</Link>
        <Link to="/facility-manager">FACILITY MANAGER</Link>
        <Link to="/market">MARKET</Link>
      </div>
      <div className={styles.navbarSection}>
        <Dropdown>
          <MenuButton className={styles.username}>{username || 'User'}</MenuButton>
          <Menu>
            <MenuItem component={Link} to="/profile">
              Profile
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              sx={{
                color: 'red',
                '&:hover': {
                  backgroundColor: 'rgba(255, 0, 0, 0.1)',
                },
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Navbar;
