import React, { useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Gauge from '../Gauge';
import styles from '../../pages/FacilityManager.module.css'
import { Button } from '@mui/joy';

interface Mission {
  id: number;
  title: string;
  description: string;
  details: string;
}

interface Campaign {
  id: number;
  title: string;
  description: string;
  missions: Mission[];
}

const dummyCampaignsData: Campaign[] = [
  {
    id: 1,
    title: 'Campaign Alpha',
    description: 'Secure dangerous SCPs.',
    missions: [
      { id: 1, title: 'Mission Alpha', description: 'Investigate SCP-001.', details: 'Detailed briefing on SCP-001 investigation.' },
      { id: 2, title: 'Mission Bravo', description: 'Secure SCP-173.', details: 'Containment procedure details for SCP-173.' },
      { id: 3, title: 'Mission Alpha', description: 'Investigate SCP-001.', details: 'Detailed briefing on SCP-001 investigation.' },
      { id: 4, title: 'Mission Bravo', description: 'Secure SCP-173.', details: 'Containment procedure details for SCP-173.' },
      { id: 5, title: 'Mission Alpha', description: 'Investigate SCP-001.', details: 'Detailed briefing on SCP-001 investigation.' },
      { id: 6, title: 'Mission Bravo', description: 'Secure SCP-173.', details: 'Containment procedure details for SCP-173.' },
      { id: 7, title: 'Mission Alpha', description: 'Investigate SCP-001.', details: 'Detailed briefing on SCP-001 investigation.' },
      { id: 8, title: 'Mission Bravo', description: 'Secure SCP-173.', details: 'Containment procedure details for SCP-173.' },
      { id: 9, title: 'Mission Alpha', description: 'Investigate SCP-001.', details: 'Detailed briefing on SCP-001 investigation.' },
      { id: 10, title: 'Mission Bravo', description: 'Secure SCP-173.', details: 'Containment procedure details for SCP-173.' },
      { id: 11, title: 'Mission Alpha', description: 'Investigate SCP-001.', details: 'Detailed briefing on SCP-001 investigation.' },
      { id: 12, title: 'Mission Bravo', description: 'Secure SCP-173.', details: 'Containment procedure details for SCP-173.' },
    ],
  },
  {
    id: 2,
    title: 'Campaign Bravo',
    description: 'Research and monitor SCPs.',
    missions: [
      { id: 3, title: 'Mission Charlie', description: 'Research SCP-682.', details: 'Ongoing research into terminating SCP-682.' },
      { id: 4, title: 'Mission Delta', description: 'Monitor SCP-096.', details: 'Behavioral analysis and containment protocols for SCP-096.' },
    ],
  },
];

const OperationsView: React.FC = () => {
  const [campaigns] = useState<Campaign[]>(dummyCampaignsData);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);

  const completedCampaigns = campaigns.filter(campaign =>
    campaign.missions.every(mission => mission.id <= 2)
  ).length;
  const totalCampaigns = campaigns.length;
  const campaignCompletionRate = Math.round((completedCampaigns / totalCampaigns) * 100);

  const [currentCampaignPage, setCurrentCampaignPage] = useState(1);
  const campaignsPerPage = 3;

  const indexOfLastCampaign = currentCampaignPage * campaignsPerPage;
  const indexOfFirstCampaign = indexOfLastCampaign - campaignsPerPage;
  const currentCampaigns = campaigns.slice(indexOfFirstCampaign, indexOfLastCampaign);

  const totalCampaignPages = Math.ceil(campaigns.length / campaignsPerPage);

  const [currentMissionPage, setCurrentMissionPage] = useState(1);
  const missionsPerPage = 5;

  const completedMissions = selectedCampaign
    ? selectedCampaign.missions.filter(mission => mission.id <= 2).length
    : 0;
  const totalMissions = selectedCampaign ? selectedCampaign.missions.length : 0;
  const missionCompletionRate = totalMissions > 0 ? Math.round((completedMissions / totalMissions) * 100) : 0;

  const openModal = (mission: Mission) => {
    setSelectedMission(mission);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleCampaignSelect = (campaign: Campaign) => {
    setSelectedCampaign(campaign);
    setCurrentMissionPage(1);
  };

  const handleBackToCampaigns = () => {
    setSelectedCampaign(null);
  };

  const currentMissions = selectedCampaign
  ? [...selectedCampaign.missions].slice((currentMissionPage - 1) * missionsPerPage, currentMissionPage * missionsPerPage)
  : [];

  const totalMissionPages = selectedCampaign ? Math.ceil(selectedCampaign.missions.length / missionsPerPage) : 0;

  return (
    <div className={styles.missionsPageContainer}>
      <div className={styles.missionsWrapper}>
        <div className={styles.sidebar}>
          {!selectedCampaign ? (
            <>
              <Gauge progress={campaignCompletionRate} />
              <Typography level="h2" textColor="#fff" sx={{ marginBottom: '20px', marginTop: '20px' }}>
                Campaign Completion
              </Typography>
              <Typography textColor="neutral.300">
                Total Campaigns: {totalCampaigns}
              </Typography>
              <Typography textColor="neutral.300">
                Campaigns Completed: {completedCampaigns}
              </Typography>
              <Typography textColor="neutral.300">
                Active Campaigns: {totalCampaigns - completedCampaigns}
              </Typography>
            </>
          ) : (
            <>
              <Gauge progress={missionCompletionRate} />
              <Typography level="h2" textColor="#fff" sx={{ marginBottom: '20px', marginTop: '20px' }}>
                Mission Completion
              </Typography>
              <Typography textColor="neutral.300">
                Total Missions: {totalMissions}
              </Typography>
              <Typography textColor="neutral.300">
                Missions Completed: {completedMissions}
              </Typography>
              <Typography textColor="neutral.300">
                Active Missions: {totalMissions - completedMissions}
              </Typography>
            </>
          )}
        </div>
  
        <div className={styles.divider}></div>
  
        <div className={styles.rowsContainer}>
          {!selectedCampaign ? (
            <>
              {currentCampaigns.map((campaign) => (
                <div
                  key={campaign.id}
                  className={styles.rowItem}
                  onClick={() => handleCampaignSelect(campaign)}
                  tabIndex={0}
                  aria-label={`Select ${campaign.title}`}
                  style={{ background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)' }}
                >
                  <div className={styles.itemInfo}>
                    <Typography level="h3" textColor="#fff">
                      {campaign.title}
                    </Typography>
                    <Typography level="body-md" textColor="neutral.300">
                      {campaign.description}
                    </Typography>
                  </div>
                </div>
              ))}
  
              <div className={styles.pagination}>
                {Array.from({ length: totalCampaignPages }, (_, index) => (
                  <Button
                    key={index + 1}
                    onClick={() => {
                      setCurrentCampaignPage(index + 1);
                      setSelectedMission(null); 
                    }}
                    variant="outlined"
                    className={`${styles.paginationButton} ${currentCampaignPage === index + 1 ? styles.paginationButtonActive : ''}`}
                  >
                    {index + 1}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            <>
              <Button onClick={handleBackToCampaigns} className={styles.backButtonOperations} variant="outlined">
                Back to Campaigns
              </Button>
  
              {currentMissions.map((mission) => (
                <div
                  key={mission.id}
                  className={styles.rowItem}
                  onClick={() => openModal(mission)}
                  tabIndex={0}
                  aria-label={`Open details for ${mission.title}`}
                  style={{ background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)' }}
                >
                  <div className={styles.itemInfo}>
                    <Typography level="h3" textColor="#fff">
                      {mission.title}
                    </Typography>
                    <Typography level="body-md" textColor="neutral.300">
                      {mission.description}
                    </Typography>
                  </div>
                </div>
              ))}
  
              <div className={styles.pagination}>
                {Array.from({ length: totalMissionPages }, (_, index) => (
                  <Button
                    key={index + 1}
                    onClick={() => {
                      setCurrentMissionPage(index + 1);
                      setSelectedMission(null);
                    }}
                    variant="outlined"
                    className={`${styles.paginationButton} ${currentCampaignPage === index + 1 ? styles.paginationButtonActive : ''}`}
                  >
                    {index + 1}
                  </Button>
                ))}
              </div>
  
              <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalVisible}
                onClose={closeModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Sheet
                  variant="outlined"
                  sx={{
                    maxWidth: 500,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                  }}
                >
                  <ModalClose variant="plain" sx={{ m: 1 }} />
                  {selectedMission && (
                    <>
                      <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                        sx={{ textAlign: 'center' }}
                      >
                        {selectedMission.title}
                      </Typography>
                      <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center' }}>
                        {selectedMission.details}
                      </Typography>
                    </>
                  )}
                </Sheet>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}  

export default OperationsView;