import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, { ReactFlowProvider, Position, Node, Edge, useReactFlow } from 'react-flow-renderer';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import { useTheme } from '@mui/joy';
import '../../styles/base.css';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import { LockedNode, UnlockedNode } from './Research/ResearchNodes';
import { ViewType, ResourceCost, ResearchNodeData, ResearchNode, InitialEdges, ResearchNodesByView, TranslateExtent } from './Research/types';
import { initialEdges, calculateInitialPositions, calculateTranslateExtent, canUnlockNode } from './Research/researchData';

const nodeTypes = {
  locked: LockedNode,
  unlocked: UnlockedNode,
};

const ResearchView = () => {
  const [currentView, setCurrentView] = useState<ViewType>('containment_protocol_training_facility');
  const [nodes, setNodes] = useState<ResearchNode[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<ResearchNode | null>(null);
  const [translateExtent, setTranslateExtent] = useState<[[number, number], [number, number]]>([[0, 0], [1600, 1200]]);
  const reactFlowInstance = useReactFlow();
  const [unlockedNodes, setUnlockedNodes] = useState<Set<string>>(new Set(['1']));
  const [opacity, setOpacity] = useState(1);

  const setInitialNodesAndEdges = useCallback(() => {
    const viewport = document.querySelector('.react-flow__viewport');
    if (viewport) {
      const viewportRect = viewport.getBoundingClientRect();
      const calculatedNodes = calculateInitialPositions(viewportRect.width, viewportRect.height);
      setNodes(calculatedNodes[currentView]);
      setEdges(initialEdges[currentView]);
      setTranslateExtent(calculateTranslateExtent(viewportRect.width, viewportRect.height));
    }
  }, [currentView]);

  useEffect(() => {
    setInitialNodesAndEdges();
    reactFlowInstance.fitView({ padding: 0.1 });
  }, [currentView, reactFlowInstance, setInitialNodesAndEdges]);

  useEffect(() => {
    const handleResize = () => {
      const viewport = document.querySelector('.react-flow__viewport');
      if (viewport) {
        const viewportRect = viewport.getBoundingClientRect();
        const calculatedNodes = calculateInitialPositions(viewportRect.width, viewportRect.height);
        setNodes(calculatedNodes[currentView]);
        setEdges(initialEdges[currentView]);
        setTranslateExtent(calculateTranslateExtent(viewportRect.width, viewportRect.height));
        reactFlowInstance.fitView({ padding: 0.1 });
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentView, reactFlowInstance]);

  const onNodeClick = (event: React.MouseEvent, node: ResearchNode) => {
    if (node.type === 'button') {
      switch(node.id) {
        case 'btn-1':
          setCurrentView('research_laboratory');
          break;
        case 'btn-2':
          setCurrentView('containment_protocol_training_facility');
          break;
        case 'btn-3':
          setCurrentView('anomalous_energy_plant');
          break;
        case 'btn-4':
          setCurrentView('scp_containment_center');
          break;
        case 'btn-5':
          setCurrentView('anomaly_research_center');
          break;
        case 'btn-6':
          setCurrentView('central_command_center');
          break;
        default:
          setCurrentView('containment_protocol_training_facility');
      }
    } else {
      setSelectedNode(node);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedNode(null);
  };

  const handleViewChange = (view: ViewType) => {
    setOpacity(0);
    setTimeout(() => {
      setCurrentView(view);
      setTimeout(() => {
        setOpacity(1);
      }, 50);
    }, 300);
  };

  const unlockNode = (nodeId: string) => {
    setUnlockedNodes(prev => new Set(Array.from(prev).concat(nodeId)));
    setNodes(nodes.map(node => 
      node.id === nodeId 
        ? { ...node, type: 'unlocked', data: { ...node.data, isLocked: false } }
        : node
    ));
    setModalOpen(false);
  };

  return (
    <Box sx={{ width: '100%', height: '90vh', display: 'flex', flexDirection: 'column' }}>
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ 
          p: 2, 
          backgroundColor: '#282c34',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 2
        }}
      >
        <Button
          variant='soft'
          color='primary'
          onClick={() => handleViewChange('research_laboratory')}
        >
          Research Laboratory
        </Button>
        <Button
          variant='soft'
          color='primary'
          onClick={() => handleViewChange('containment_protocol_training_facility')}
        >
          Containment Protocol Training Facility
        </Button>
        <Button
          variant='soft'
          color='primary'
          onClick={() => handleViewChange('anomalous_energy_plant')}
        >
          Anomalous Energy Plant
        </Button>
        <Button
          variant='soft'
          color='primary'
          onClick={() => handleViewChange('scp_containment_center')}
        >
          SCP Containment Center
        </Button>
        <Button
          variant='soft'
          color='primary'
          onClick={() => handleViewChange('anomaly_research_center')}
        >
          Anomaly Research Center
        </Button>
        <Button
          variant='soft'
          color='primary'
          onClick={() => handleViewChange('central_command_center')}
        >
          Central Command Center
        </Button>
      </Stack>

      <Box
        sx={{
          width: '100%',
          height: '4px', 
          my: 0.5,
          opacity: 0.6
        }}
      />

      <Box sx={{ flex: 1 }}>
        <ReactFlowProvider>
          <div style={{ width: '100%', height: '100%' }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodeClick={onNodeClick}
              preventScrolling={true}
              zoomOnScroll={false}
              zoomOnPinch={false}
              panOnScroll={false}
              panOnDrag={true}
              translateExtent={translateExtent}
              style={{ backgroundColor: '#282c34' }}
              nodeTypes={nodeTypes}
              nodesDraggable={false}
              nodesConnectable={false}
            />
            <style>
              {`
                .react-flow__viewport {
                  opacity: ${opacity};
                  transition: opacity 0.3s ease-in-out;
                }
              `}
            </style>
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={modalOpen}
              onClose={closeModal}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Sheet
                variant="outlined"
                sx={{
                  maxWidth: 500,
                  borderRadius: 'md',
                  p: 3,
                  boxShadow: 'lg',
                }}
              >
                <ModalClose variant="plain" sx={{ m: 1 }} />
                {selectedNode && (
                  <>
                    <Typography
                      component="h2"
                      id="modal-title"
                      level="h4"
                      textColor="inherit"
                      fontWeight="lg"
                      mb={1}
                      sx={{ textAlign: 'center' }}
                    >
                      {selectedNode.data.label}
                    </Typography>
                    <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center', mb: 2 }}>
                      {selectedNode.data.description}
                    </Typography>
                    
                    {selectedNode.data.isLocked && selectedNode.data.requirements && (
                      <Box sx={{ mb: 2 }}>
                        <Typography level="body-sm" sx={{ fontWeight: 'bold', mb: 1 }}>
                          Requirements:
                        </Typography>
                        <Stack spacing={1}>
                          {selectedNode.data.requirements.map((reqId: string) => {
                            const reqNode = nodes.find(n => n.id === reqId);
                            return (
                              <Typography 
                                key={reqId} 
                                level="body-sm"
                                sx={{ 
                                  color: unlockedNodes.has(reqId) ? 'success.main' : 'warning.main',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1
                                }}
                              >
                                {unlockedNodes.has(reqId) ? '✓' : '•'} {reqNode?.data.label || 'Unknown'}
                              </Typography>
                            );
                          })}
                        </Stack>
                      </Box>
                    )}

                    {selectedNode.data.cost && (
                      <Box sx={{ mb: 2 }}>
                        <Typography level="body-sm" sx={{ fontWeight: 'bold', mb: 1 }}>
                          Cost:
                        </Typography>
                        <Stack direction="row" spacing={2}>
                          {Object.entries(selectedNode.data.cost).map(([resource, amount]) => 
                            typeof amount === 'number' ? (
                              <Typography key={resource} level="body-sm">
                                {resource}: {amount}
                              </Typography>
                            ) : null
                          )}
                        </Stack>
                      </Box>
                    )}

                    <Stack direction="row" spacing={2} justifyContent="center">
                      {selectedNode.data.isLocked ? (
                        <Button 
                          onClick={() => unlockNode(selectedNode.id)}
                          color="primary"
                          disabled={!canUnlockNode(selectedNode.id, unlockedNodes, nodes)}
                        >
                          Unlock Research
                        </Button>
                      ) : (
                        <Button onClick={() => {}}>
                          Allocate Resources
                        </Button>
                      )}
                    </Stack>
                  </>
                )}
              </Sheet>
            </Modal>
          </div>
        </ReactFlowProvider>
      </Box>
    </Box>
  );
};

export default ResearchView;
