import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useUser } from '../../context/UserContext';
import { fetchWithToken } from '../../utils/fetchUtils';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      SCP: Facility Manager {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loginError, setLoginError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);

  const { loginUser } = useUser();

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const rememberedUsername = localStorage.getItem('rememberedUsername');
      if (rememberedUsername) {
        setUsername(rememberedUsername);
        autoLogin(token);
      }
    }
  }, []);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      autoLogin(token);
    }
  }, []);
  
  const autoLogin = async (token: string) => {
    try {
      const response = await fetchWithToken('users/me', { method: 'GET' }, token);
      if (response.ok) {
        loginUser({
          id: response.data.id,
          username: response.data.username,
          email: response.data.email,
          facilityId: response.data.facilityId,
          level: response.data.level,
          experience: response.data.experience,
          prestige_level: response.data.prestige_level,
          avatarUrl: response.data.avatarUrl,
        });
      } else {
        throw new Error('Failed to auto-login');
      }
    } catch (error) {
      console.error('Auto-login error:', error);
      localStorage.removeItem('token');
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetchWithToken('users/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        localStorage.setItem('token', response.data.token);
        if (rememberMe) {
          localStorage.setItem('rememberedUsername', username);
        } else {
          localStorage.removeItem('rememberedUsername');
        }
        await loginUser({
          id: response.data.id,
          username: response.data.username,
          email: response.data.email,
          facilityId: response.data.facilityId,
          level: response.data.level,
          experience: response.data.experience,
          prestige_level: response.data.prestige_level,
          avatarUrl: response.data.avatarUrl,
        });  
        navigate('/home');
      } else {
        throw new Error(response.data.message || 'Login failed');
      }
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('An unknown error occurred');
      }
      setLoginError(true);
      setTimeout(() => {
        setLoginError(false);
      }, 5000);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {loginError && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            position: 'absolute',
            top: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            opacity: 0,
            animation: 'fadein 0.5s forwards',
            '@keyframes fadein': {
              '0%': {
                opacity: 0,
                top: 0,
              },
              '100%': {
                opacity: 1,
                top: 10,
              },
            },
          }}>
          Login failed: {errorMessage}
        </Alert>
      )}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember me"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}