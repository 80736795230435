import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useUser } from '../context/UserContext';
import { useFacility } from '../context/FacilityContext';
import Button from '@mui/joy/Button';
import FacilityGridView from '../components/FacilityManager/FacilityGridView';
import ResourcesView from '../components/FacilityManager/ResourcesView';
import ResearchView from '../components/FacilityManager/ResearchView';
import OperationsView from '../components/FacilityManager/OperationsView';
import FacilityOverview from '../components/FacilityManager/FacilityOverview';
import { fetchWithToken } from '../utils/fetchUtils';
import theme from '../joyTheme';
import styles from './FacilityManager.module.css';
import '../styles/base.css';

export default function FacilityManager() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const defaultView = 'resources';
  
  const view = searchParams.get('view') || defaultView;
  
  const handleViewChange = (newView: string) => {
    setSearchParams({ view: newView });
  };

  const { user } = useUser();
  const { facility, setFacility } = useFacility();
  const [facilityLevel, setFacilityLevel] = useState<number | null>(null);
  const [resources, setResources] = useState<any>(null);

  useEffect(() => {
    console.log("Token in localStorage:", localStorage.getItem('token'));
    const fetchFacilityData = async () => {
      if (user && user.id) {
        try {
          const token = localStorage.getItem('token') || undefined;
          if (!token) throw new Error('No token available');
          const response = await fetchWithToken(`facility/${user.id}`, {
            method: 'GET',
          }, token);

          if (response.ok) {
            const data = response.data;
            setFacilityLevel(data.facility.level);
            setResources(data.resources);
            setFacility(data.facility);
          } else {
            throw new Error('Failed to fetch facility data');
          }
        } catch (error) {
          console.error('Error fetching facility data:', error);
        }
      }
    };

    fetchFacilityData();
  }, [user]);

  const buttonStyle = {
    fontFamily: (theme.typography as any).fontFamily,
  };

  return (
    <div className="app-background">
      <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0} />
      <div className="page-header-text">
        {facility ? (
          <h1>{facility.name ? facility.name.toUpperCase() : 'FACILITY'}</h1>
        ) : (
          <h1>Loading facility...</h1>
        )}
      </div>
      <div className={styles.facilityManagerNavigationHeader}>
        <Button 
          variant={view === 'resources' ? 'solid' : 'outlined'} 
          className="btn" 
          sx={buttonStyle} 
          onClick={() => handleViewChange('resources')}
        >
          RESOURCES
        </Button>
        <Button 
          variant={view === 'facility-management' ? 'solid' : 'outlined'} 
          className="btn" 
          sx={buttonStyle} 
          onClick={() => handleViewChange('facility-management')}
        >
          FACILITY MANAGEMENT
        </Button>
        <Button 
          variant={view === 'facility-overview' ? 'solid' : 'outlined'} 
          className="btn" 
          sx={buttonStyle} 
          onClick={() => handleViewChange('facility-overview')}
        >
          FACILITY OVERVIEW
        </Button>
        <Button 
          variant={view === 'research' ? 'solid' : 'outlined'} 
          className="btn" 
          sx={buttonStyle} 
          onClick={() => handleViewChange('research')}
        >
          RESEARCH
        </Button>
        <Button 
          variant={view === 'operations' ? 'solid' : 'outlined'} 
          className="btn" 
          sx={buttonStyle} 
          onClick={() => handleViewChange('operations')}
        >
          OPERATIONS
        </Button>
      </div>
      <div className={styles.facilityManagerContainer}>
        <div className={styles.panel}>
          {view === 'facility-management' && <FacilityGridView />}
          {view === 'resources' && <ResourcesView facilityId={user ? user.id : 0} />}
          {view === 'research' && <ResearchView />}
          {view === 'operations' && <OperationsView/>}
          {view === 'facility-overview' && <FacilityOverview />}
        </div>
      </div>
    </div>
  );
}