import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalClose,
  Typography,
  Sheet,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@mui/joy';
import { fetchWithToken } from '../utils/fetchUtils';
import { useUser } from '../context/UserContext';
import styles from './AvatarModal.module.css';

interface AvatarData {
  id: number;
  name: string;
  image_url: string;
  foundation_credits_price: number;
  foundation_tokens_price: number;
  owned: boolean;
}

interface Resources {
  foundation_credits: number;
  foundation_tokens: number;
}

interface AvatarModalProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (avatarId: number, avatar: AvatarData) => void;
}

const AvatarModal: React.FC<AvatarModalProps> = ({ open, onClose, onSelect }) => {
  const { user } = useUser();
  const [avatars, setAvatars] = useState<AvatarData[]>([]);
  const [resources, setResources] = useState<Resources | null>(null);
  const [loading, setLoading] = useState<number | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const avatarsResponse = await fetchWithToken('avatars', { method: 'GET' });
        setAvatars(avatarsResponse.data.avatars);

        if (user && user.id) {
          const facilityResponse = await fetchWithToken(`facility/${user.id}`, {
            method: 'GET',
          });
          setResources(facilityResponse.data.resources);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    loadData();
  }, [user]);

  const handlePurchase = async (avatarId: number) => {
    setLoading(avatarId);
    try {
      const response = await fetchWithToken(`avatars/${avatarId}/purchase`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error(response.data.message || 'Failed to purchase avatar');
      }

      const avatarsResponse = await fetchWithToken('avatars', { method: 'GET' });
      setAvatars(avatarsResponse.data.avatars);

      const facilityResponse = await fetchWithToken(`facility/${user?.id}`, {
        method: 'GET',
      });
      setResources(facilityResponse.data.resources);
    } catch (error) {
      console.error('Failed to purchase avatar:', error);
    } finally {
      setLoading(null);
    }
  };

  const handleAvatarSelect = (avatarId: number, avatar: AvatarData) => {
    if (onSelect) {
      onSelect(avatarId, avatar);
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 800,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
          width: '90%',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          component="h2"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
          sx={{ textAlign: 'center' }}
        >
          Select an Avatar
        </Typography>
        {resources && (
          <Box className={styles.resources}>
            <Typography level="body-md">
              Credits: {resources.foundation_credits}
            </Typography>
            <Typography level="body-md">
              Tokens: {resources.foundation_tokens}
            </Typography>
          </Box>
        )}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {avatars &&
            avatars.map((avatar) => (
              <Grid xs={12} sm={6} md={4} lg={3} key={avatar.id}>
                <Sheet
                  variant="outlined"
                  className={styles.avatarCard}
                  sx={{
                    p: 2,
                    textAlign: 'center',
                    borderRadius: 'sm',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <img
                      src={avatar.image_url}
                      alt={avatar.name}
                      className={styles.avatarImage}
                    />
                    <Typography level="h4" mt={1}>
                      {avatar.name}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    {avatar.foundation_credits_price > 0 && (
                      <Typography level="body-md">
                        Credits Price: {avatar.foundation_credits_price}
                      </Typography>
                    )}
                    {avatar.foundation_tokens_price > 0 && (
                      <Typography level="body-md">
                        Tokens Price: {avatar.foundation_tokens_price}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="solid"
                    color={avatar.owned ? 'success' : 'neutral'}
                    onClick={() =>
                      avatar.owned
                        ? handleAvatarSelect(avatar.id, avatar)
                        : handlePurchase(avatar.id)
                    }
                    disabled={loading === avatar.id}
                    sx={{ mt: 1 }}
                  >
                    {loading === avatar.id ? (
                      <CircularProgress size="sm" />
                    ) : avatar.owned ? (
                      'Equip'
                    ) : (
                      'Purchase'
                    )}
                  </Button>
                </Sheet>
              </Grid>
            ))}
        </Grid>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{ mt: 2, display: 'block', mx: 'auto' }}
        >
          Close
        </Button>
      </Sheet>
    </Modal>
  );
};

export default AvatarModal;
