import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import '../../styles/base.css';
import { useEffect } from 'react';
import styles from '../../pages/FacilityManager.module.css';
import fetchWithToken from '../../utils/fetchUtils';

interface BuildingType {
  id: number;
  name: string;
  description: string | null;
  image_url: string | null;
}

const FacilityGridView = () => {
  const [buildings, setBuildings] = useState<BuildingType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const buildingColors = {
    'Central Command Center': 'linear-gradient(to bottom, #000000 40%, #a6c0fe 150%)',
    'Anomalous Research Center': 'linear-gradient(to bottom, #000000 40%, #f3dd97 150%)',
    'Containment Protocol Training': 'linear-gradient(to bottom, #000000 40%, #4d727e 150%)',
    'Research Laboratory Center': 'linear-gradient(to bottom, #000000 40%, #1f55c2 150%)',
    'SCP Containment Wings': 'linear-gradient(to bottom, #000000 40%, #c74cb9 150%)',
    'Anomalous Energy Center': 'linear-gradient(to bottom, #000000 40%, #4d7e5a 150%)'
  };

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await fetchWithToken('building-types', { method: 'GET' });
        
        console.log('Raw response:', response);
        
        if (!response || !response.data) {
          throw new Error('No response data received');
        }
        
        const buildingsArray = response.data.data;
        if (!Array.isArray(buildingsArray)) {
          console.error('Response data structure:', response);
          throw new Error(`Expected response.data to be an array but got ${typeof buildingsArray}`);
        }
        
        setBuildings(buildingsArray);
      } catch (err) {
        console.error('Error details:', err);
        setError(err instanceof Error ? err.message : 'Failed to load buildings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBuildings();
  }, []);

  if (isLoading) {
    return <div>Loading buildings...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleBuildingClick = (buildingId: string) => {
    navigate(`/facility/building/${buildingId}`);
  };

  return (
    <div className={styles.facilityContainer}>
      <div className={styles.facilityWrapper}>
        <div className={styles.facilityGrid}>
            {buildings.map((building) => (
            <div
              key={building.id}
              className={styles.gridItem}
              onClick={() => handleBuildingClick(building.name)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleBuildingClick(building.name);
                }
              }}
              tabIndex={0}
              aria-label={`Open details for ${building.name}`}
              style={{
                background: buildingColors[building.name as keyof typeof buildingColors] || 
              'linear-gradient(to bottom, #000000 40%, #808080 150%)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className={styles.gradientOverlay}></div>
              <img
                src={building.image_url || ''}
                alt={building.name}
                style={{
                  width: '300px',
                  height: '300px',
                  objectFit: 'contain',
                  margin: 'auto',
                  display: 'block',
                }}
              />
              <div className={styles.itemInfo}>
                <Typography
                  component="h3"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                  sx={{ textAlign: 'center', fontSize: '1.2rem !important' }}
                >
                  {building.name}
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center', fontSize: '0.875rem !important' }}>
                  {building.description}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FacilityGridView;
