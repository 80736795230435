import React from 'react';
import styles from './SCPCard.module.css';

interface SCPData {
  id: number;
  base_number: string;
  scp_number: string;
  name: string;
  motto: string;
  classification: string;
  image_url: string;
  rarity?: string;
  wear?: string;
  is_variant?: boolean;
}

interface SCPCardProps {
  scp: SCPData;
  onSelect: (scp: SCPData) => void;
  isModalView?: boolean;
  isOwned?: boolean;
}

function capitalizeFirstLetter(string: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const SCPCard: React.FC<SCPCardProps> = ({ scp, onSelect, isModalView, isOwned }) => {
  const { name, image_url, wear, rarity } = scp;

  const capitalizedRarity = capitalizeFirstLetter(rarity || '');
  const capitalizedWear = capitalizeFirstLetter(wear || '');

  const containerClassNames = [
    styles.cardContainer,
    isModalView ? styles.modalView : styles.normalView,
    !isOwned ? styles.grayscale : '',
    rarity ? styles[`rarity${capitalizedRarity}`] : '',
  ].filter(Boolean).join(' ');

  const wearClassNames = [
    styles.wearOverlay,
    wear ? styles[`wear${capitalizedWear}`] : '',
  ].filter(Boolean).join(' ');

  return (
    <div className={containerClassNames} onClick={() => onSelect(scp)}>
      {image_url && <img src={image_url} alt={name} className={styles.cardImage} />}
      <div className={wearClassNames} />
    </div>
  );
};

export default SCPCard;
