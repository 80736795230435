import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './FilterSettings.module.css';

interface SCPData {
  base_number: string;
}

interface FilterSettingsProps {
  scpData: SCPData[];
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  showUnowned: boolean;
  setShowUnowned: (value: boolean) => void;
  showBaseCards: boolean;
  setShowBaseCards: (value: boolean) => void;
  showVariantCards: boolean;
  setShowVariantCards: (value: boolean) => void;
  selectedClassifications: string[];
  setSelectedClassifications: (value: string[]) => void;
  selectedRarities: string[];
  setSelectedRarities: (value: string[]) => void;
  selectedWearLevels: string[];
  setSelectedWearLevels: (value: string[]) => void;
}

const FilterSettings: React.FC<FilterSettingsProps> = ({
  scpData,
  searchTerm,
  setSearchTerm,
  showUnowned,
  setShowUnowned,
  showBaseCards,
  setShowBaseCards,
  showVariantCards,
  setShowVariantCards,
  selectedClassifications,
  setSelectedClassifications,
  selectedRarities,
  setSelectedRarities,
  selectedWearLevels,
  setSelectedWearLevels,
}) => {
  const options = Array.from(new Set(scpData.map((scp) => scp.base_number)));

  return (
    <div className={styles.filterSettings}>
      <Autocomplete
        options={options}
        renderInput={(params) => (
          <TextField {...params} label="Search SCPs" variant="outlined" />
        )}
        onInputChange={(event, value) => setSearchTerm(value || '')}
        value={searchTerm || null}
        className={styles.searchBar}
      />
      <Accordion defaultExpanded className={styles.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filters-content"
          id="filters-header"
        >
          <Typography variant="h6">Filters</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showUnowned}
                  onChange={(e) => setShowUnowned(e.target.checked)}
                />
              }
              label="Show Unowned"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showBaseCards}
                  onChange={(e) => setShowBaseCards(e.target.checked)}
                />
              }
              label="Base Cards"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showVariantCards}
                  onChange={(e) => setShowVariantCards(e.target.checked)}
                />
              }
              label="Variant Cards"
            />
          </FormGroup>
          <div className={styles.autocompleteGroup}>
            <Autocomplete
              multiple
              options={['Safe', 'Euclid', 'Keter', 'Thaumiel']}
              renderInput={(params) => (
                <TextField {...params} label="Classification" variant="outlined" />
              )}
              value={selectedClassifications}
              onChange={(event, value) => setSelectedClassifications(value || [])}
              className={styles.filterAutocomplete}
            />
            <Autocomplete
              multiple
              options={['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary']}
              renderInput={(params) => (
                <TextField {...params} label="Rarity" variant="outlined" />
              )}
              value={selectedRarities}
              onChange={(event, value) => setSelectedRarities(value || [])}
              className={styles.filterAutocomplete}
            />
            <Autocomplete
              multiple
              options={['Mint', 'Polished', 'Weathered', 'Battered', 'Ruined']}
              renderInput={(params) => (
                <TextField {...params} label="Wear Level" variant="outlined" />
              )}
              value={selectedWearLevels}
              onChange={(event, value) => setSelectedWearLevels(value || [])}
              className={styles.filterAutocomplete}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

interface OptionType {
  label: string;
  value: string;
}

interface DropDownSearchProps {
  setSelectedValue: (value: OptionType | null) => void;
  width?: string;
  label?: string;
  options: OptionType[];
  defaultSelectedValue?: string;
}

const DropDownSearch: React.FC<DropDownSearchProps> = ({
  setSelectedValue,
  width = "auto",
  label = "",
  options = [],
  defaultSelectedValue = "",
}) => {
  const [value, setValue] = useState<OptionType | null>(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (defaultSelectedValue) {
      const selectedOption = options.find(
        (opt) => opt.value === defaultSelectedValue
      );
      setValue(selectedOption || null);
    }
  }, [defaultSelectedValue, options]);

  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        setSelectedValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} autoComplete="off" />
      )}
      style={{ width }}
      autoHighlight
    />
  );
};

export default FilterSettings;
