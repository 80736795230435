import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { useUser } from '../context/UserContext';
import Navbar from '../components/Navbar';
import SCPCard from '../components/SCPCard';
import styles from './SCPCollection.module.css';
import { fetchWithToken } from '../utils/fetchUtils';
import SCPModal from '../components/SCPCollection/SCPModal';
import FilterSettings from '../components/SCPCollection/FilterSettings';
import { access } from 'fs';

interface SCPData {
    id: number;
    base_number: string;
    scp_number: string;
    name: string;
    motto: string;
    classification: string;
    image_url: string;
    rarity?: string;
    wear?: string;
    is_variant?: boolean;
    maintenance_cost?: number;
    series?: string;
    acquired_at?: string;
}

export default function SCPCollection() {
    const { user } = useUser();
    const [scpData, setScpData] = useState<SCPData[]>([]);
    const [userCards, setUserCards] = useState<SCPData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showUnowned, setShowUnowned] = useState(false);
    const [showBaseCards, setShowBaseCards] = useState(true);
    const [showVariantCards, setShowVariantCards] = useState(true);
    const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);
    const [selectedRarities, setSelectedRarities] = useState<string[]>([]);
    const [selectedWearLevels, setSelectedWearLevels] = useState<string[]>([]);
    const [selectedSCP, setSelectedSCP] = useState<SCPData | null>(null);
    const [selectedSCPVariants, setSelectedSCPVariants] = useState<SCPData[]>([]);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

    function capitalizeFirstLetter(str: string | undefined): string {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      }

    useEffect(() => {
        const fetchScpData = async () => {
          try {
            const response = await fetchWithToken('scps', { method: 'GET' });
            if (response.ok) {
              const scpDataWithVariant = response.data.map((scp: SCPData) => ({
                ...scp,
                rarity: capitalizeFirstLetter(scp.rarity),
                wear: capitalizeFirstLetter(scp.wear),
                is_variant: !!scp.rarity,
                maintenance_cost: scp.maintenance_cost,
                series: scp.series,
                acquired_at: scp.acquired_at,
              }));
              setScpData(scpDataWithVariant);
            } else {
              console.error('Error fetching SCP data:', response);
            }
          } catch (error) {
            console.error("Error fetching SCP data:", error);
          }
        };
        fetchScpData();
      }, []);

    useEffect(() => {
        const fetchUserCards = async () => {
            try {
                const token = localStorage.getItem('token') || undefined;
                if (token && user?.id) {
                    const response = await fetchWithToken(`cards/user/${user.id}`, { method: 'GET' }, token);
                    if (response.ok) {
                        setUserCards(response.data);
                    } else {
                        console.error('Error fetching user cards:', response);
                    }
                }
            } catch (error) {
                console.error("Error fetching user cards:", error);
            }
        };
        if (user) {
            fetchUserCards();
        }
    }, [user]);

    const isOwned = (scp: SCPData): boolean => {
        return userCards.some(userCard => userCard.id === scp.id);
    };

    const handleSelectSCP = (scp: SCPData) => {
        if (scp.is_variant) {
            const variants = userCards.filter(userCard => userCard.base_number === scp.base_number && userCard.is_variant);
            setSelectedSCPVariants(variants);
            setSelectedVariantIndex(0);
            setSelectedSCP(variants[0] || scp);
        } else {
            const ownedBaseCard = userCards.find(userCard => userCard.base_number === scp.base_number && !userCard.is_variant);
            setSelectedSCPVariants([]);
            setSelectedVariantIndex(0);
            setSelectedSCP(ownedBaseCard || scp);
        }
    };

    const handleNextVariant = () => {
        if (selectedSCPVariants && selectedVariantIndex < selectedSCPVariants.length - 1) {
            setSelectedVariantIndex(prevIndex => {
                const newIndex = prevIndex + 1;
                setSelectedSCP(selectedSCPVariants[newIndex]);
                return newIndex;
            });
        }
    };

    const handlePrevVariant = () => {
        if (selectedSCPVariants && selectedVariantIndex > 0) {
            setSelectedVariantIndex(prevIndex => {
                const newIndex = prevIndex - 1;
                setSelectedSCP(selectedSCPVariants[newIndex]);
                return newIndex;
            });
        }
    };

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            setSelectedSCP(null);
        }
    };

    const scpDataWithUserCards = scpData.map(scp => {
        const userCardEntries = userCards.filter(userCard => userCard.scp_number === scp.scp_number);
        const wearLevels = userCardEntries.map(userCard => capitalizeFirstLetter(userCard.wear));
        return {
          ...scp,
          isOwned: userCardEntries.length > 0,
          wearLevels: wearLevels.length > 0 ? wearLevels : ['None'],
          count: userCardEntries.length,
        };
      });
      
    const filteredSCPs = scpDataWithUserCards.filter(scp => {
    return (
        (!searchTerm || scp.base_number.includes(searchTerm)) &&
        (showUnowned || scp.isOwned) &&
        ((showBaseCards && !scp.is_variant) || (showVariantCards && scp.is_variant)) &&
        (selectedClassifications.length === 0 || selectedClassifications.includes(scp.classification)) &&
        (selectedRarities.length === 0 || (scp.rarity && selectedRarities.includes(scp.rarity))) &&
        (selectedWearLevels.length === 0 || scp.wearLevels.some(wear => selectedWearLevels.includes(wear)))
    );
    });

    return (
        <div className="app-background">
            <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0} />
            <div className="page-header-text">
                <Typography variant="h1">SCP COLLECTION</Typography>
            </div>
            <div className={styles.scpCollectionContainer}>
                <FilterSettings
                    scpData={scpData}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    showUnowned={showUnowned}
                    setShowUnowned={setShowUnowned}
                    showBaseCards={showBaseCards}
                    setShowBaseCards={setShowBaseCards}
                    showVariantCards={showVariantCards}
                    setShowVariantCards={setShowVariantCards}
                    selectedClassifications={selectedClassifications}
                    setSelectedClassifications={setSelectedClassifications}
                    selectedRarities={selectedRarities}
                    setSelectedRarities={setSelectedRarities}
                    selectedWearLevels={selectedWearLevels}
                    setSelectedWearLevels={setSelectedWearLevels}
                />
                <div className={styles.scpCards}>
                    <div className={styles.scpCardsContainer}>
                        <div className={styles.scpCardsGrid}>
                            {filteredSCPs.length > 0 ? (
                                filteredSCPs.map((scp, index) => (
                                    <SCPCard
                                        key={index}
                                        scp={scp}
                                        onSelect={handleSelectSCP}
                                        isOwned={scp.isOwned}
                                    />
                                ))
                            ) : (
                                <Typography variant="h6" className={styles.noResultsText}>
                                    No SCPs match the current filters.
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {selectedSCP && (
                <SCPModal
                    selectedSCP={selectedSCP}
                    selectedSCPVariants={selectedSCPVariants}
                    selectedVariantIndex={selectedVariantIndex}
                    handlePrevVariant={handlePrevVariant}
                    handleNextVariant={handleNextVariant}
                    isOwned={isOwned}
                    handleClose={handleClose}
                />
            )}
        </div>
    );
}
