import React, { useState, useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { fetchWithToken } from '../../utils/fetchUtils';
import styles from '../../pages/FacilityManager.module.css';
import '../../styles/base.css';
import { parse } from 'path';
import { LinearProgress } from '@mui/joy';
import Box from '@mui/joy/Box';

interface Resource {
  iconSrc: string;
  backgroundSrc: string;
  name: string;
  amount: number;
  cap: number;
  description: string;
}

interface ResourcesViewProps {
  facilityId: number;
}

interface ResourceDisplayProps {
  resource: Resource;
}

const getProgressColor = (percentage: number) => {
  if (percentage >= 90) return 'danger';
  if (percentage >= 70) return 'warning';
  return 'success';
};

const ResourceDisplay: React.FC<ResourceDisplayProps> = ({ resource }) => {
  const percentage = (resource.amount / resource.cap) * 100;
  const progressColor = getProgressColor(percentage);

  return (
    <div className={styles.resourceProgress}>
      <LinearProgress
        determinate
        value={percentage}
        color={progressColor}
        variant="solid"
        sx={{
          '--LinearProgress-thickness': '24px',
          '--LinearProgress-radius': '12px',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          'overflow': 'hidden'
        }}
      />
      <Typography
        level="body-sm"
        className={styles.resourceAmount}
      >
        {resource.amount.toFixed(1)} / {resource.cap.toFixed(1)}
      </Typography>
    </div>
  );
};

const ResourcesView: React.FC<ResourcesViewProps> = ({ facilityId }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedResource, setSelectedResource] = useState<Resource | null>(null);

  useEffect(() => {
    const fetchFacilityData = async () => {
      try {
        const response = await fetchWithToken(`facility/${facilityId}`, { method: 'GET' });
        const data = response.data;

        if (data && Array.isArray(data.resources)) {
          setResources(
            data.resources.map((res: any) => ({
              iconSrc: res.icon_url,
              backgroundSrc: res.background_url,
              name: res.display_name,
              amount: res.amount,
              cap: res.cap,
              description: res.description,
            }))
          );
        } else {
          console.error('Invalid resources data format:', data);
          setResources([]);
        }
      } catch (error) {
        console.error('Error fetching facility data:', error);
        setResources([]);
      }
    };
    fetchFacilityData();
  }, [facilityId]);

  const openModal = (resource: Resource) => {
    setSelectedResource(resource);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className={styles.resourcesContainer}>
      <div className={styles.resourcesWrapper}>
        <div className={styles.resourcesGrid}>
          {resources.map((resource, index) => (
            <div
              key={index}
              className={styles.gridItem}
              onClick={() => openModal(resource)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  openModal(resource);
                }
              }}
              tabIndex={0}
              aria-label={`Open details for ${resource.name}`}
              style={{ backgroundImage: `url(${resource.backgroundSrc})` }}
            >
              <div className={styles.gradientOverlay}></div>
              <div
                className={styles.icon}
                style={{
                  backgroundImage: `url(${resource.iconSrc})`,
                  width: "200px",
                  height: "200px",
                  backgroundSize: 'cover',
                  mixBlendMode: 'screen',
                  margin: 'auto',
                  display: 'block'
                }}
              ></div>
              <div className={styles.itemInfo}>
                <Typography level="h3" textColor="#fff">
                  {resource.name}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <ResourceDisplay resource={resource} />
                </Box>
              </div>
            </div>
          ))}

          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={modalVisible}
            onClose={closeModal}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 500,
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
              }}
            >
              <ModalClose variant="plain" sx={{ m: 1 }} />
              {selectedResource && (
                <>
                  <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                    sx={{ textAlign: 'center' }}
                  >
                    {selectedResource.name}
                  </Typography>
                  <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center' }}>
                    {selectedResource.description}
                  </Typography>
                  <Typography id="modal-amount" textColor="text.secondary" sx={{ textAlign: 'center', marginTop: '10px' }}>
                    Amount: {selectedResource.amount}
                  </Typography>
                </>
              )}
            </Sheet>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ResourcesView;
