import React from 'react';
import { useFacility } from '../../context/FacilityContext';
import styles from './FacilityOverview.module.css';

export default function FacilityOverview() {
  const { facility } = useFacility();
  const currentXP = facility?.experience || 0;
  const nextLevelXP = 100 * (facility?.level || 0 + 1); // Simplified XP calculation

  return (
    <div className={styles.facilityOverviewContainer}>
      <div className={styles.facilityOverviewWrapper}>
        <div className={styles.facilityOverview}>
          <div className={styles.levelWidget}>
            <h2>Facility Level</h2>
            <div className={styles.levelDisplay}>
              <div className={styles.levelNumber}>Level {facility?.level || 0}</div>
              <div className={styles.xpProgress}>
                <div className={styles.xpBar}>
                  <div 
                    className={styles.xpFill} 
                    style={{ width: `${(currentXP / nextLevelXP) * 100}%` }}
                  />
                </div>
                <div className={styles.xpText}>
                  {currentXP} / {nextLevelXP} XP
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 