import React, { useState, useEffect } from 'react';
import { Avatar as MuiAvatar, Box, Typography } from '@mui/joy';
import Navbar from '../components/Navbar';
import { useUser } from '../context/UserContext';
import AvatarModal from '../components/AvatarModal';
import { updateUserAvatar } from '../utils/userApi';
import { fetchWithToken } from '../utils/fetchUtils';
import styles from './Profile.module.css';

const Profile: React.FC = () => {
  const { user } = useUser();
  const [userCards, setUserCards] = useState<any[]>([]);
  const [facility, setFacility] = useState<any>(null);
  const [resources, setResources] = useState<any>(null);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);

  const handleAvatarClick = () => {
    setIsAvatarModalOpen(true);
  };

  const handleAvatarSelect = async (avatarId: number) => {
    try {
      if (user?.id) {
        await updateUserAvatar(user.id, avatarId);
        window.location.reload();
      }
    } catch (error) {
      console.error('Failed to update avatar:', error);
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      if (user?.id) {
        try {
          // Fetch user's cards
          const cardsResponse = await fetchWithToken(`cards/user/${user.id}`, { method: 'GET' });
          if (cardsResponse.ok) {
            setUserCards(cardsResponse.data);
          }

          // Fetch facility data
          const facilityResponse = await fetchWithToken(`facility/${user.id}`, { method: 'GET' });
          if (facilityResponse.ok) {
            setFacility(facilityResponse.data.facility);
            const resourceArray = facilityResponse.data.resources;
            const resourceMap = resourceArray.reduce((acc: any, resource: any) => {
              acc[resource.resource_key] = resource.amount;
              return acc;
            }, {});
            setResources(resourceMap);
          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
    };

    fetchProfileData();
  }, [user]);

  return (
    <div className="app-background">
      <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0} />
      <div className="page-header-text">
        <Typography level="h1">PROFILE</Typography>
      </div>
      
      <Box className={styles.profileContainer}>
        <Box className={styles.avatarSection}>
          <Box className={styles.avatarWrapper} onClick={handleAvatarClick}>
            <MuiAvatar
              src={user?.avatarUrl || 'https://via.placeholder.com/150'}
              alt="User Avatar"
              className={styles.avatar}
            sx={{ width: 150, height: 150 }}
          />
            <Box className={styles.avatarOverlay}>
              <Typography level="h4" className={styles.avatarEditIcon}>
                ✎
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* User Information Section */}
        <Box className={styles.infoSection}>
          <Typography level="h3" className={styles.sectionTitle}>
            User Information
          </Typography>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Username:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {user?.username || 'N/A'}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Email:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {user?.email || 'N/A'}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Level:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {user?.level || 0}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Experience:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {user?.experience || 0}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Prestige Level:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {user?.prestige_level || 0}
            </Typography>
          </Box>
        </Box>

        {/* Facility Information Section */}
        <Box className={styles.infoSection}>
          <Typography level="h3" className={styles.sectionTitle}>
            Facility Information
          </Typography>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Facility Name:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {facility?.name || 'No Facility'}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Facility Level:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {facility?.level || 0}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Alert Level:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {facility?.alert_level || 'N/A'}
            </Typography>
          </Box>
        </Box>

        {/* Card Collection Stats */}
        <Box className={styles.infoSection}>
          <Typography level="h3" className={styles.sectionTitle}>
            Card Collection
          </Typography>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Total Cards:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {userCards?.length || 0}
            </Typography>
          </Box>
        </Box>

        {/* Resources Overview */}
        <Box className={styles.infoSection}>
          <Typography level="h3" className={styles.sectionTitle}>
            Resources
          </Typography>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Foundation Credits:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {resources?.foundation_credits || 0}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography level="body-lg" className={styles.label}>
              Foundation Tokens:
            </Typography>
            <Typography level="body-lg" className={styles.value}>
              {resources?.foundation_tokens || 0}
            </Typography>
          </Box>
        </Box>
      </Box>
      <AvatarModal 
        open={isAvatarModalOpen} 
        onClose={() => setIsAvatarModalOpen(false)} 
        onSelect={handleAvatarSelect}
      />
    </div>
  );
};

export default Profile;
